<template>
    <div :class="[
    'component',
    'component__table',
    scrollableVertically ? 'component__table--scrollable-vertically' : '',
    scrollableHorizontally ? 'component__table--scrollable-horizontally' : '',
    `component__table--rows-align-${rowsAlign}`,
    margin ? `component__table--margin-${margin}` : ''
    ]">
        <div class="container">
            <div class="columns columns--scrollable">
                <div class="column">
                    <div class="component__table_filters">
                        <base-filter
                            v-if="filtersActive"
                            :actions-active="false"
                            :details-active="true"
                            :details-items-number="items.length"
                            :details-items-number-of="detailsItemsNumberOf"
                            :details-items-name="itemName"
                            :filter-page-size="detailsPageSize"
                            :list-active="false"
                            :search-active="false"
                            :sort-active="false"
                            :select-active="false"
                            @emit-filter-page-size="data => handleEmit({ message: 'filter-page-size', value: data })"
                        />
                    </div>
                    <div class="component__table_main">
                        <table>
                            <keep-alive>
                                <list-table-head
                                    :checkbox="checkbox"
                                    :checkbox-name="checkboxName"
                                    :checkbox-checked="listTableHeadAllVisibleChecked"
                                    :checkbox-by-key="checkboxByKey"
                                    :disabled="checkboxDisabled"
                                    :labels="labels"
                                    :sort-by="sortBy || grandParentSortBy"
                                    :sort-direction="sortDirection || grandParentSortDirection"
                                    :list-emit-name="`${listEmitName}-list-action`"
                                    @emit-sort="handleSort"
                                />
                            </keep-alive>
                            <tbody>
                            <template v-if="componentTableAddActive">
                                <component
                                    :name="`${name}-add`"
                                    :is="componentTableAddItem"
                                    :labels="labels"
                                    @emit="handleEmit"
                                ></component>
                            </template>
                            <template v-if="outputItems.length || componentTableAddActive">
                                <template v-for="(item, index) in outputItems">
                                    <component
                                        :name="name"
                                        :key="preventRerender ? index : index + rerenderIndex"
                                        :is="componentTableItem"
                                        :item="item"
                                        :checkbox="checkbox"
                                        :checkbox-emit-name="listEmitName"
                                        :allow-single="allowSingle"
                                        :collapse-active="componentTableCollapseItemActive === index"
                                        :list-emit-name="listEmitName"
                                        :index="index"
                                        :labels="labels"
                                        :disabled="disabled || actionsDisabled || outputActionsDisabled"
                                        :selected="selected"
                                        :disallow="disallow"
                                        :extra-data="extraData"
                                        @emit="handleEmit"
                                    ></component>
                                    <template v-if="componentTableCollapseItem">
                                        <component
                                            class="component__table_collapse_item"
                                            :key="preventRerender ? 'collapse' + (index) : 'collapse' + (index + rerenderIndex)"
                                            :is="componentTableCollapseItem"
                                            :item="item"
                                            :name="name"
                                            :collapse-active="componentTableCollapseItemActive === index"
                                            @emit="handleEmit"
                                        ></component>
                                    </template>
                                </template>
                            </template>
                            <template v-else>
                                <tr>
                                    <td :colspan="labels.length + 1">
                                        <component-empty-state
                                            v-if="emptyStateActive"
                                            :item="pluralItemName || singularItemName"
                                            :title="outputEmptyStateTitle"
                                            :content="outputEmptyStateContent"
                                            :submit-button-active="outputEmptyStateButtonActive"
                                            :submit-button-text="outputEmptyStateButtonText"
                                            @emit-submit="handleEmit({ message: 'emit-empty-state-submit' })"
                                        />
                                    </td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ListTableHead from '@/components/default/shared/ListTableHead'
import { sortByKey } from '@/functions'
import BaseFilter from '@/components/default/filters/BaseFilter'
import ComponentEmptyState from '@/components/default/shared/ComponentEmptyState'
import { TablePreferences } from '@/TablePreferences'

export default {
  name: 'component-table',
  components: { ComponentEmptyState, BaseFilter, ListTableHead },
  data () {
    return {
      sortBy: '',
      sortType: '',
      sortDirection: '',
      sortSubBy: '',
      sortSubType: '',
      rerenderIndex: 0
    }
  },
  props: {
    disallow: {
      type: [Array, String, Number]
    },
    actionsDisabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String
    },
    allowSingle: {
      type: Boolean,
      default: false
    },
    tablePreferencesActive: {
      type: Boolean,
      default: false
    },
    itemName: {
      type: [String, Array],
      default: () => ['item', 'items']
    },
    rowsAlign: {
      type: String,
      default: 'middle',
      validator (value) {
        const allowed = ['top', 'middle', 'bottom']
        return allowed.includes(value)
      }
    },
    labels: {
      /*
      Array of String
      [String]

      or

      Array of Object
      {
         key: String,
         name: String,
         type: one of ['string', 'number', 'date', 'array', 'object'],
         subKey: String,
         subType: one of ['string', 'number', 'date', 'array', 'object'],
         sortable: Boolean
      }
      */
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    items: {
      type: [Array, String],
      required: true,
      default: () => []
    },
    detailsItemsNumberOf: {
      type: Number
    },
    detailsPageSize: {
      type: Number
    },
    checkbox: {
      type: Boolean
    },
    checkboxDisabled: {
      type: Boolean
    },
    checkboxName: {
      type: String
    },
    checkboxByKey: {
      type: String,
      default: 'id'
    },
    componentTableItem: {
      type: Object,
      required: true
    },
    componentTableAddActive: {
      type: Boolean,
      default: false
    },
    componentTableAddItem: {
      type: Object
    },
    componentTableCollapseItem: {
      type: Object
    },
    componentTableCollapseItemActive: {
      type: [Number, String]
    },
    emptyStateActive: {
      type: Boolean,
      default: true
    },
    emptyStateTitle: {
      type: String
    },
    emptyStateContent: {
      type: String
    },
    emptyStateButtonActive: {
      type: Boolean
    },
    emptyStateButtonText: {
      type: String
    },
    listEmitName: {
      type: String
    },
    sortQueryable: {
      type: Boolean,
      default: false
    },
    preventRerender: {
      type: Boolean,
      default: false
    },
    filtersActive: {
      type: Boolean,
      default: false
    },
    scrollableVertically: {
      type: Boolean,
      default: false
    },
    scrollableHorizontally: {
      type: Boolean,
      default: false
    },
    selected: {
      type: [Array, Object],
      default: () => []
    },
    margin: {
      type: String
    },
    extraData: {
      type: Object
    }
  },
  computed: {
    listTableHeadAllVisibleChecked () {
      let allVisible = true
      const checkboxActive = this.checkbox
      const checkboxByKey = this.checkboxByKey
      const selected = this.selected
      const items = this.items

      if (!checkboxActive) return false

      if (selected) {
        for (let item of items) {
          if (!selected.includes(item[checkboxByKey])) {
            allVisible = false
          }
        }
      }

      return allVisible
    },
    outputActionsDisabled () {
      return this.grandParent.actionsDisabled
    },
    outputEmptyStateTitle () {
      return this.emptyStateTitle || this.grandParent.emptyStateTitleOutput || undefined
    },
    outputEmptyStateContent () {
      return this.emptyStateContent || this.grandParent.emptyStateContent || undefined
    },
    outputEmptyStateButtonActive () {
      return this.emptyStateButtonActive || this.grandParent.emptyStateButtonActive || false
    },
    outputEmptyStateButtonText () {
      return this.emptyStateButtonText || this.grandParent.emptyStateButtonText || undefined
    },
    outputItems () {
      return (this.grandParentSortQueryable && this.sortQueryable) ? this.items : sortByKey(this.items, this.sortBy, this.sortDirection, this.sortType, this.sortSubBy, this.sortSubType)
    },
    grandParent () {
      return this.$parent.$parent
    },
    grandParentIsComponentList () {
      return this.grandParent.$vnode.componentOptions.tag === 'component-list'
    },
    grandParentSortQueryable () {
      return this.grandParent.paginationHasPages
    },
    grandParentSortBy () {
      return this.grandParent.sortBy
    },
    grandParentSortDirection () {
      return this.grandParent.sortDirection
    },
    grandParentTablePreferences () {
      return this.grandParent.tablePreferencesActive
    },
    singularItemName () {
      const itemName = this.grandParent.itemName || this.itemName
      if (!itemName) return
      return itemName.constructor === Array ? itemName[0] : itemName
    },
    pluralItemName () {
      const itemName = this.grandParent.itemName || this.itemName
      if (!itemName) return
      return itemName.constructor === Array ? itemName[1] : ''
    }
  },
  watch: {
    items () {
      this.rerenderIndex++
    }
  },
  methods: {
    handleEmit (data) {
      const message = data.message
      const value = data.value
      this.$emit(message, value)
    },
    handleSort (data) {
      this.handleEmit({ message: 'emit-collapse', value: '' })

      const sortQueryable = data.sortQueryable
      const oldSortBy = this.sortBy
      const oldSortDirection = this.sortDirection
      const newSortBy = data.sortBy
      const newSortType = data.sortType
      const newSortDirection = data.sortDirection
      const newSortSubBy = data.sortSubBy
      const newSortSubType = data.sortSubType

      if ((oldSortBy === newSortBy) && (oldSortDirection === newSortDirection)) {
        this.sortBy = ''
        this.sortType = ''
        this.sortDirection = ''
        this.sortSubBy = ''
        this.sortSubType = ''
        this.$emit('emit-sort', { sortBy: '', sortDirection: '', sortQueryable: sortQueryable })
      } else {
        this.sortBy = newSortBy
        this.sortType = newSortType
        this.sortDirection = newSortDirection
        this.sortSubBy = newSortSubBy
        this.sortSubType = newSortSubType
        this.$emit('emit-sort', { sortBy: newSortBy, sortDirection: newSortDirection, sortQueryable: sortQueryable })
      }
    }
  },
  mounted () {
    if (this.tablePreferencesActive) {
      const tablePreferences = TablePreferences.getTablePreferences(this.componentTableName)
      const tablePreferencesSortBy = tablePreferences ? tablePreferences.sortBy : ''
      const tablePreferencesSortDirection = tablePreferences ? tablePreferences.sortDirection : ''

      if (tablePreferences) {
        if (tablePreferencesSortBy) {
          this.sortBy = tablePreferencesSortBy
        }
        if (tablePreferencesSortDirection) {
          this.sortDirection = tablePreferencesSortDirection
        }
      }
    }
  },
  inject: ['$validator']
}
</script>

<style lang="scss">
@import "~@/assets/scss/components/default/shared/componenttable";
</style>
